<template>
  <CButtonGroup>
    <CButton
        v-if="!allowEdit"
        variant="outline"
        color="dark"
        @click="$emit('close', true)"
    >
      Close
    </CButton>
    <CButton
        v-if="!allowEdit"
        variant="outline"
        color="dark"
        @click="enableEdit"
    >
      Edit
    </CButton>
    <CButton
        v-if="allowEdit"
        variant="outline"
        color="dark"
        @click="cancelEdit"
    >
      Cancel
    </CButton>
    <CButton
        v-if="allowEdit"
        variant="outline"
        color="info"
        @click="$emit('save', true)"
    >
      Save
    </CButton>
    <CButton
        v-if="allowEdit"
        variant="outline"
        color="danger"
        @click="$emit('delete-prompt', true)"
    >
      Delete
    </CButton>
  </CButtonGroup>
</template>

<script>
export default {
  name: "DetailsButtonGroup",
  methods: {
    enableEdit(){
      this.$emit('edit', true);
      this.$store.commit('set', ['allowEdit', true]);
    },
    cancelEdit(){
      this.$emit('cancel', true);
      this.$store.commit('set', ['allowEdit', false]);
    }
  },
  computed: {
    allowEdit: {
      get: function() {
        return this.$store.state.allowEdit
      },
      set: function (){
      }
    }
  }
}
</script>

<style scoped>

</style>