<template>
  <div>
    <CSpinner v-if="$apollo.queries.salesOffice.loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <DeleteModal
        entity="sales office"
        :name="itemName"
        :nid="salesOffice.nid"
        :show.sync="showDeleteItemModal"
        v-on:delete="deleteItem"
        v-on:cancel-delete="toggleDeleteItemModal"
    />
    <CNavbar
        type="light"
        class="navbar-partner"
    >
      <CNavItem
          :to="`/partners/vendor/${vendorNid}`"
          class="nav-item-partner"
      >
        Vendor
      </CNavItem>
      <CNavItem
          :to="`/partners/vendor/${vendorNid}/sales-offices`"
          class="nav-item-partner"
      >
        Sales Offices
      </CNavItem>
    </CNavbar>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol class="text-left" sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({path: `/partners/vendor/${vendorNid}/sales-offices`})"
            />
          </CCol>
          <CCol sm="8">
            <h2>{{itemName}}</h2>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SalesOfficeForm
            v-bind:vendor-nid="vendorNid"
            v-bind:sales-office-in="salesOfficeEdited"
            v-on:update-item="updateItem"
            v-on:error="catchError"
        />
      </CCardBody>
      <CCardFooter
          class="text-left">
        <DetailsButtonGroup
            v-on:save="saveItem"
            v-on:cancel="resetItem"
            v-on:delete-prompt="toggleDeleteItemModal"
            v-on:close="$router.push({ path: `/partners/vendor/${vendorNid}/sales-offices`})"
        />
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DetailsButtonGroup from "@/components/common/DetailsButtonGroup";
import DeleteModal from "@/components/common/DeleteModal";
import SalesOfficeForm from "@/apps/partners/components/SalesOfficeForm";
const clonedeep = require('lodash.clonedeep')

const querySalesOffice = gql`
query salesOffice($nid: ID!) {
  salesOffice(nid: $nid) {
    nid
    activePapp
    msActivePapp
    imActivePapp
    name
    externalId
    vendor{
      nid
      name
      operatesIn{
        nid
        name
      }
    }
    officeType

    #branding
    plan
    disableRegionalBranding
    officeProfilePicture{
      hrefOrig
    }
    displayRegions{
      numericPostalCode
    }
    msDisplayRegions{
      numericPostalCode
    }
    imDisplayRegions{
      numericPostalCode
    }
    #handover
    handoverMethod
    officeHandoverEmail
    handoverRegions{
      numericPostalCode
    }
    msHandoverRegions{
      numericPostalCode
    }
    imHandoverRegions{
      numericPostalCode
    }
    #contact
    addressLine1
    addressLine2
    postalCode{
      nid
      postalCode
    }
    city
    country{
      nid
      name
    }
    officePhone
    company
    website

    # opening hours
    openingHoursMondayStart
    openingHoursMondayEnd
    lunchBreakMondayStart
    lunchBreakMondayEnd
    openingHoursMondayStatus

    openingHoursTuesdayStart
    openingHoursTuesdayEnd
    lunchBreakTuesdayStart
    lunchBreakTuesdayEnd
    openingHoursTuesdayStatus

    openingHoursWednesdayStart
    openingHoursWednesdayEnd
    lunchBreakWednesdayStart
    lunchBreakWednesdayEnd
    openingHoursWednesdayStatus

    openingHoursThursdayStart
    openingHoursThursdayEnd
    lunchBreakThursdayStart
    lunchBreakThursdayEnd
    openingHoursThursdayStatus

    openingHoursFridayStart
    openingHoursFridayEnd
    lunchBreakFridayStart
    lunchBreakFridayEnd
    openingHoursFridayStatus

    openingHoursSaturdayStart
    openingHoursSaturdayEnd
    lunchBreakSaturdayStart
    lunchBreakSaturdayEnd
    openingHoursSaturdayStatus

    openingHoursSundayStart
    openingHoursSundayEnd
    lunchBreakSundayStart
    lunchBreakSundayEnd
    openingHoursSundayStatus

    # reporting
    headOfSalesGender
    headOfSalesFirstName
    headOfSalesLastName
    headOfSalesEmail

    # MS Handover
    msSendHtmlInstantHandover
    msHtmlInstantHandoverTemplate
    msHtmlInstantHandoverEmails
    msHtmlInstantHandoverEmailSubject
    msSendCsvHandover
    msCsvHandoverSchedule
    msCsvHandoverFormat
    msCsvHandoverEmails
    msCsvHandoverEmailSubject
    msCsvHandoverTemplate

    #misc
    services
    description

    # IM fields
    imRegion
    imListingsUrl
    imIndexed
  }
}
`
const updateSalesOffice = gql`
  mutation updateSalesOffice($nid: ID!, $salesOffice: SalesOfficeInput!){
    updateSalesOffice(nid: $nid, salesOffice: $salesOffice){
        nid
    }
  }
`
const deleteSalesOffice = gql`
  mutation deleteSalesOffice($nid: ID!){
    deleteSalesOffice(nid: $nid)
  }
`

export default {
  name: "SalesOffice",
  components: {
    DetailsButtonGroup,
    DeleteModal,
    SalesOfficeForm
  },
  data() {
    return {
      error: null,
      showDeleteItemModal: false,
      salesOffice: {},
      salesOfficeEdited: {},
      salesOfficeSave: {}
    }
  },
  methods: {
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    catchError(event){
      this.error = event
    },
    updateItem(data){
      this.salesOfficeSave = data
    },
    saveItem(){
      const {nid, __typename, ...updatedObj} = this.salesOfficeSave;
      this.$apollo.mutate({
        mutation: updateSalesOffice,
        variables: {
          nid: nid,
          salesOffice: updatedObj
        }
      })
      .then(res => {
        this.$emit('save-item', this.salesOfficeEdited);
        this.$store.commit('set', ['addModalShow', false]);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: `/partners/vendor/${this.vendorNid}/sales-offices`});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleDeleteItemModal(){
      this.showDeleteItemModal = !this.showDeleteItemModal;
    },
    resetItem(){
      this.salesOfficeEdited = clonedeep(this.$apolloData.data.salesOffice);
    },
    deleteItem(){
      this.$apollo.mutate({
        mutation: deleteSalesOffice,
        variables: {
          nid: this.salesOffice.nid
        }
      })
      .then(res => {
        this.$emit('delete-sales-region', this.salesOffice.nid);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: `/partners/vendor/${this.vendorNid}/sales-offices`});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    }
  },
  computed: {
    allowEdit() {
      return this.$store.state.allowEdit
    },
    itemName(){
      return this.salesOfficeEdited.name
    },
    vendorNid(){
      return this.salesOffice? this.salesOffice.vendor? this.salesOffice.vendor.nid: null: null;
    }
  },
  apollo: {
    salesOffice: {
      query: querySalesOffice,
      error(error) {
        this.error = error.message;
      },
      variables() {
        return {
          nid: this.$route.params.sales_office_nid
        }
      },
      result ({ data, loading, networkStatus }) {
        this.resetItem();
      },
      fetchPolicy: "cache-and-network"
    }
  }
}
</script>

<style scoped>
  .navbar-partner{
    align-items: start;
    align-content: start;
    justify-content: start;
    padding-left: 0px;
    margin-left: 0px;
  }
  .nav-item-partner{
    list-style: none;
  }
</style>
